import styled from "styled-components";

const Container = styled.div`
  margin-bottom: 30px;
  display: flex;
  border-radius: 10px;
  box-shadow: #9fa1da 0 0 0 1px inset;
`;

type SwitcherProps = {
  active: boolean;
};

const SwitcherButton = styled.button<SwitcherProps>`
  height: 32px;
  width: 100%;
  color: ${(props) => props.theme.colors.text};
  border-radius: 10px;
  background: ${(props) =>
    props.active ? props.theme.gradients.button : "transparent"};
`;

interface Props {
  options: Option<string>[];
  value: string;
  onChange: (value: string) => void;
}

export const Switcher: React.FC<Props> = ({ options, value, onChange }) => {
  const handleClick = (optionValue: string) => (e: any) => {
    e.preventDefault();

    if (value !== optionValue) onChange(optionValue);
  };

  return (
    <Container>
      {options.map((option) => (
        <SwitcherButton
          key={option.value}
          active={value === option.value}
          onClick={handleClick(option.value)}
        >
          {option.label}
        </SwitcherButton>
      ))}
    </Container>
  );
};
