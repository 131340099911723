import { useState } from "react";
import { useGate, useStore } from "effector-react";
import styled from "styled-components";

import { Paging } from "shared/paging";
import { Manager } from "shared/types/manager";
import { Colored } from "shared/ui/Colored";
import { Email } from "shared/ui/Email";
import { AssignAsAdminIcon } from "shared/ui/icons/AssignAsAdmin";
import { DeleteUserIcon } from "shared/ui/icons/DeleteUser";
import { UserOptionsIcon } from "shared/ui/icons/UserOptions";
import { PageLayout } from "shared/ui/PageLayout";
import { Popover, PopoverOption } from "shared/ui/popover";
import {
  EmptyTablePlaceholder,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "shared/ui/table";
import { Typography } from "shared/ui/Typography";
import { Underlined } from "shared/ui/Underlined";

import {
  AssignUserModal,
  assignUserModel,
  CreateUserButton,
  CreateUserModal,
  DeleteUserModal,
  deleteUserModel,
  RemoveUserFromAdminsModal,
  removeUserFromAdminsModel,
} from "features/user";

import { isAdmin } from "./lib";
import {
  $data,
  $isEmpty,
  $usersTotal,
  changePageSize,
  loadNext,
  loadPrev,
  loadTotalsFx,
  UsersGate,
} from "./model";
import { Loader } from "shared/ui/Loader";

const ButtonTableHeadCell = styled(TableHeadCell)`
  display: flex;
  justify-content: flex-end;
`;

const UserOptionsButton = styled.button`
  padding: 10px;
  vertical-align: middle;
  display: inline-flex;
`;

const Amount = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
`;

export const UsersPage: React.FC = () => {
  useGate(UsersGate);
  const { data, ...paging } = useStore($data);
  const isEmpty = useStore($isEmpty);
  const totalUsers = useStore($usersTotal);

  const [userId, setUserId] = useState<string | null>(null);

  const handleClosePopover = () => setUserId(null);

  const handleTogglePopover = (user: Manager) => () =>
    setUserId((value) => (value ? null : user.id));

  const handleDeleteUser = (user: Manager) => () =>
    deleteUserModel.markUserForDeletion(user);

  const handleAssignAdmin = (user: Manager) => () => {
    assignUserModel.markUserForAssignation(user);
  };

  const handleRemoveUser = (user: Manager) => () => {
    removeUserFromAdminsModel.markUserForRemoving(user);
  };
  const loading = useStore(loadTotalsFx.pending);

  if (loading) {
    return <Loader sideWidth="238px" />;
  }

  return (
    <PageLayout
      titleComponent={
        <Typography variant="bold20">
          <Underlined>Users</Underlined>
        </Typography>
      }
      amountComponent={
        <Amount variant="bold20">Total amount of Users: {totalUsers}</Amount>
      }
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell align="left">First Name</TableHeadCell>
            <TableHeadCell>Last Name</TableHeadCell>
            <TableHeadCell>Email Address</TableHeadCell>
            <TableHeadCell>Status</TableHeadCell>
            <ButtonTableHeadCell>
              <CreateUserButton />
            </ButtonTableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isEmpty && (
            <EmptyTablePlaceholder>You have no Users yet</EmptyTablePlaceholder>
          )}
          {data.map((user) => (
            <TableRow key={user.id}>
              <TableCell align="left">{user.firstName}</TableCell>
              <TableCell>{user.lastName}</TableCell>
              <TableCell>
                <Email>{user.email}</Email>
              </TableCell>
              <TableCell>
                <Colored color={isAdmin(user) ? "green" : "yellow"}>
                  {isAdmin(user) ? "admin" : "user"}
                </Colored>
              </TableCell>
              <TableCell align="right">
                <Popover
                  isOpen={userId === user.id}
                  onClose={handleClosePopover}
                  content={
                    <>
                      <PopoverOption onClick={handleDeleteUser(user)}>
                        <DeleteUserIcon />
                        Delete User
                      </PopoverOption>
                      <PopoverOption
                        onClick={
                          isAdmin(user)
                            ? handleRemoveUser(user)
                            : handleAssignAdmin(user)
                        }
                      >
                        <AssignAsAdminIcon />
                        {isAdmin(user)
                          ? "Remove from Admins"
                          : "Assign as Admin"}
                      </PopoverOption>
                    </>
                  }
                >
                  <UserOptionsButton onClick={handleTogglePopover(user)}>
                    <UserOptionsIcon />
                  </UserOptionsButton>
                </Popover>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Paging
        {...paging}
        loadNext={loadNext}
        loadPrev={loadPrev}
        changePageSize={changePageSize}
      />
      <DeleteUserModal />
      <CreateUserModal />
      <AssignUserModal />
      <RemoveUserFromAdminsModal />
    </PageLayout>
  );
};
