import { useRef } from "react";
import styled from "styled-components";

import { CrossIcon } from "./icons/Cross";

const Input = styled.input`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: transparent;
  padding-left: 20px;
  border: none;
  outline: none;
  color: #ffffff;
  &::placeholder {
    color: #9fa1da;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Icon = styled.div`
  cursor: pointer;
  height: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  value: string;
  onSearch: (value: string) => void;
};

export const Search: React.FC<Props> = ({ value, onSearch }) => {

  const onSubmit = (value: string) => {
    onSearch(value);
  };
  const inputRef = useRef<HTMLInputElement | null>(null);
  
  const onClear = () => {
    if (inputRef.current && inputRef.current.value) {
      onSearch("");
    }
  };

  return (
    <Container>
      <Input
        ref={inputRef}
        placeholder="Search name or email"
        value={value}
        onChange={(e) => onSubmit(e.target.value)}
      />
      <Icon onClick={() => onClear()}>
        <CrossIcon />
      </Icon>
    </Container>
  );
};
