import { Redirect, Route } from "react-router-dom";
import { useStore } from "effector-react";

import { PageLayout } from "shared/ui/PageLayout";

import { PageLink } from "./ui/PageLink";
import { RaceInfo } from "./ui/RaceInfo";
import { Races } from "./ui/Races";
import { Statistics } from "./ui/Statistics";
import { getHorses } from "./lib";
import { $activeRace, $races, $statistic, setActiveRace } from "./model";

export const RacesPage: React.FC = () => {
  const races = useStore($races);
  const activeRace = useStore($activeRace);
  const statistic = useStore($statistic);

  return (
    <>
      <PageLayout
        titleComponent={
          <div>
            <PageLink to="/races/today">Today Races</PageLink>
            <PageLink to="/races/yesterday">Yesterday Races</PageLink>
          </div>
        }
      >
        <Route path="/races/today">
          <Races day="today" />
        </Route>
        <Route path="/races/yesterday">
          <Races day="yesterday" />
        </Route>
        <Redirect from="/races/*?" to="/races/today" />
      </PageLayout>
      {activeRace ? (
        <RaceInfo
          horses={getHorses(activeRace, races)}
          onClose={() => setActiveRace(null)}
        />
      ) : (
        <Statistics statistic={statistic} />
      )}
    </>
  );
};
