import { useStore } from "effector-react";
import styled from "styled-components";

import { Button } from "shared/ui/Button";
import { Modal } from "shared/ui/Modal";
import { OutlinedButton } from "shared/ui/OutlinedButton";
import { Typography } from "shared/ui/Typography";

import {
  $isDeleteUserModalVisible,
  $isSuccessMessageVisible,
  $userMarkedForDeletion,
  cancelUserDeletion,
  deleteUserFx,
} from "./model";

const ModalButton = styled(Button)`
  margin-top: 18px;
  margin-bottom: 16px;
`;

const NextButton = styled(Button)`
  margin-top: 18px;
`;

export const DeleteUserModal: React.FC = () => {
  const isVisible = useStore($isDeleteUserModalVisible);
  const user = useStore($userMarkedForDeletion);
  const isSuccess = useStore($isSuccessMessageVisible);

  const handleClose = () => cancelUserDeletion();
  const handleDeleteUser = () => {
    if (user) deleteUserFx([user.id]);
  };

  return (
    <Modal isOpen={isVisible} onClose={handleClose}>
      {isSuccess ? (
        <>
          <Typography variant="bold20">
            User {user?.firstName} {user?.lastName} has successfully been
            deleted
          </Typography>

          <NextButton onClick={handleClose}>Continue</NextButton>
        </>
      ) : (
        <>
          <Typography variant="bold20">
            Delete user {user?.firstName} {user?.lastName}?
          </Typography>
          <ModalButton onClick={handleDeleteUser}>Yes</ModalButton>
          <OutlinedButton onClick={handleClose}>No</OutlinedButton>
        </>
      )}
    </Modal>
  );
};
