import {
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from "effector";
import { createGate } from "effector-react";

import { api } from "shared/api";
import { interval } from "shared/lib/interval";
import { Pick, Region, Statistic } from "shared/types/races";

import {
  getDayInterval,
  sortPicksByRaces,
} from "./lib";
import { Day, RaceIdentifier } from "./types";

const RACES_TIMEOUT = 10000;

const startPollPicks = createEvent();
const stopPollPicks = createEvent();
const startPollTodayRaces = createEvent();
const stopPollTodayRaces = createEvent();

const { tick: loadRaces } = interval({
  timeout: RACES_TIMEOUT,
  start: startPollTodayRaces,
  stop: stopPollTodayRaces,
});

export const loadRacesFx = createEffect<string, Region[]>(api.fetchRaces);
export const loadStatisticFx = createEffect<string, Statistic>(
  api.fetchStatistic
);

export const firstLoadRaces = createEvent<string>();

export const setActiveRace = createEvent<RaceIdentifier | null>();
export const setActivePicks = createEvent<Pick[]>();
export const setSortedPicks = createEvent<Pick[]>();

export const resetStatistics = createEvent();
export const resetActiveRace = createEvent();
export const resetActivePicks = createEvent();
export const resetSortedPicks = createEvent();

export const $races = restore(loadRacesFx.doneData, []);
export const $isLoaderVisible = createStore(false);
export const $statistic = restore(loadStatisticFx.doneData, null);
export const $activeRace = restore(setActiveRace, null);
export const $activePicks = restore(setActivePicks, []);
export const $sortedPicks = restore(setSortedPicks, []);

$statistic.reset(resetStatistics);
$activeRace.reset(resetActiveRace);
$activePicks.reset(resetActivePicks);
$sortedPicks.reset(resetSortedPicks);

$isLoaderVisible.on(firstLoadRaces, () => true);
$isLoaderVisible.on(loadRacesFx.finally, () => false);

sample({
  source: [$races, $activePicks, $activeRace],
  fn: ([races, activePicks, activeRace]) =>
    sortPicksByRaces(races, activePicks, activeRace),
  target: setSortedPicks,
});

export const RacesGate = createGate<{ day: Day }>();

const $day = RacesGate.state.map((state) => state.day);

sample({
  clock: loadRaces,
  source: $day,
  fn: getDayInterval,
  target: loadRacesFx,
});

guard({
  source: $day.map(getDayInterval),
  filter: RacesGate.status,
  target: [loadStatisticFx, firstLoadRaces],
});
sample({
  clock: RacesGate.open,
  source: $day.map(getDayInterval),
  target: [loadStatisticFx],
});

forward({
  from: firstLoadRaces,
  to: loadRacesFx,
});

forward({
  from: RacesGate.state,
  to: [resetActiveRace, resetActivePicks, resetSortedPicks],
});

forward({
  from: RacesGate.open,
  to: [startPollPicks, startPollTodayRaces],
});

forward({
  from: RacesGate.close,
  to: [stopPollPicks, stopPollTodayRaces],
});
