export const RacesIcon: React.FC = () => (
  <svg
    width="31"
    height="19"
    viewBox="0 0 31 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0273 4.01941H20.3607C24.0886 4.01941 26.1107 5.82861 26.1107 9.29395C26.1107 12.7593 24.0886 14.5685 20.3607 14.5685H16.4088C16.1521 14.5685 15.944 14.7766 15.944 15.0333C15.944 15.29 16.1521 15.498 16.4088 15.498H20.3607C24.6409 15.498 27.1107 13.2727 27.1107 9.29395C27.1107 5.31523 24.6409 3.08984 20.3607 3.08984H10.0273C5.74714 3.08984 3.27734 5.31523 3.27734 9.29395C3.27734 13.2727 5.74714 15.498 10.0274 15.498H13.9792C14.2359 15.498 14.444 15.29 14.444 15.0333C14.444 14.7766 14.2359 14.5685 13.9792 14.5685H10.0274C6.29943 14.5685 4.27734 12.7593 4.27734 9.29395C4.27734 5.82861 6.29942 4.01941 10.0273 4.01941Z"
      fill="white"
    />
    <path
      d="M10.1934 0.929562H20.1934C25.1639 0.929562 29.1934 4.67517 29.1934 9.29562C29.1934 13.9161 25.1639 17.6617 20.1934 17.6617H16.4081C16.1514 17.6617 15.9434 17.8698 15.9434 18.1265C15.9434 18.3831 16.1514 18.5912 16.4081 18.5912H20.1934C25.7162 18.5912 30.1934 14.4294 30.1934 9.29562C30.1934 4.16179 25.7162 0 20.1934 0H10.1934C4.67051 0 0.193359 4.16179 0.193359 9.29562C0.193359 14.4294 4.67051 18.5912 10.1934 18.5912H13.9786C14.2353 18.5912 14.4434 18.3831 14.4434 18.1265C14.4434 17.8698 14.2353 17.6617 13.9786 17.6617H10.1934C5.2228 17.6617 1.19336 13.9161 1.19336 9.29562C1.19336 4.67517 5.2228 0.929562 10.1934 0.929562Z"
      fill="white"
    />
    <rect x="13.498" y="14" width="1" height="5" fill="#9FA1DA" />
    <rect x="15.8691" y="14" width="1" height="5" fill="#9FA1DA" />
  </svg>
);
