import { AxiosError } from "axios";
import { createEffect, createEvent, createStore, forward, sample } from "effector";

import { history } from "router/history";
import { api, LoginForm } from "shared/api";
import { Manager } from "shared/types/manager";

export enum AuthStatus {
  UNKNOWN = "unknown",
  AUTHORIZED = "authorized",
  UNATHORIZED = "unauthorized",
}

export const loginFx = createEffect<LoginForm, any, AxiosError>();
export const logoutFx = createEffect<void, any>();
export const loadAccountFx = createEffect<void, Manager>();

export const resetLoginErrors = createEvent();

export const $passwordError = createStore<string>("")
  .on(loginFx.failData, (_, { response }) => {
    return response?.data.message;
  })
  .reset([loginFx.done, resetLoginErrors]);

export const $loading = createStore(false)
  .on(loginFx, () => true)
  .on(loginFx.done, () => false)
  .on(loginFx.fail, () => false);

export const $account = createStore<Manager | null>(null)
  .on(loadAccountFx.doneData, (_, result) => result)
  .reset(loadAccountFx.fail);

export const $authStatus = createStore(AuthStatus.UNKNOWN)
  .on(loadAccountFx.done, () => AuthStatus.AUTHORIZED)
  .on(loadAccountFx.fail, () => AuthStatus.UNATHORIZED);

loginFx.use(api.login);
logoutFx.use(api.logout);
loadAccountFx.use(api.loadAccount);

forward({
  from: [loginFx.done, logoutFx.done],
  to: loadAccountFx,
});

sample({
  clock: loadAccountFx.failData,
  target: logoutFx
});

sample({
  clock: loadAccountFx.failData,
  fn:()=> history.push("/login")
});

loginFx.done.watch(() => {
  history.push("/");
});

logoutFx.done.watch(() => {
  history.push("/login");
});
