import React from "react";
import { useStore } from "effector-react";
import styled from "styled-components";

import { Plan } from "shared/types/customer";
import { formatISODate } from "shared/lib/date";
import { DATE_WITH_TIME_FORMAT, SHORT_DATE_FORMAT } from "shared/lib/date";
import { getFullName } from "shared/lib/getFullName";
import { calculatePlan } from "shared/lib/calculatePlan";
import { GreenText } from "shared/ui/GreenText";
import { SidePanel } from "shared/ui/SidePanel";
import { Typography } from "shared/ui/Typography";
import { YellowText } from "shared/ui/YellowText";

import { getPaymentType } from "./lib";
import {
  $customerDetails,
  $customerPayments,
  $customerPaymentsLoaded,
  hideCustomerInfo,
} from "./model";

const Container = styled.div`
  margin-top: 36px;
  padding-top: 36px;
  border-top: 1px solid #1f2340;
`;

const Title = styled(Typography)`
  display: block;
  margin-bottom: 24px;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  &:last-child {
    margin: 0;
  }
`;

export const CustomerInfo: React.FC = () => {
  const customerDetails = useStore($customerDetails);
  const customerPayments = useStore($customerPayments);
  const customerPaymentsLoaded = useStore($customerPaymentsLoaded);
  if (!customerDetails || !customerPaymentsLoaded) return null;

  return (
    <SidePanel
      title={customerDetails ? getFullName(customerDetails) : ""}
      onClose={hideCustomerInfo}
    >
      <Container>
        <Title variant="semiBold18">Personal details</Title>
        <ul>
          <ListItem>
            <Typography variant="regular16">Email</Typography>
            <div style={{ marginLeft: 16, textAlign: "right" }}>
              <Typography variant="semiBold16">
                {customerDetails.email || "-"}
              </Typography>
            </div>
          </ListItem>
          <ListItem>
            <Typography variant="regular16">Address 1</Typography>
            <Typography variant="semiBold16">
              {customerDetails.firstAddress || "-"}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="regular16">Address 2</Typography>
            <Typography variant="semiBold16">
              {customerDetails.secondAddress || "-"}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="regular16">Suburb</Typography>
            <Typography variant="semiBold16">
              {customerDetails.suburb || "-"}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="regular16">State</Typography>
            <Typography variant="semiBold16">
              {customerDetails.state || "-"}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="regular16">Postcode</Typography>
            <Typography variant="semiBold16">
              {customerDetails.postcode || "-"}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="regular16">Day of Registration</Typography>
            <Typography variant="semiBold16">
              {customerDetails?.dayOfRegistration &&
                formatISODate(
                  customerDetails.dayOfRegistration,
                  SHORT_DATE_FORMAT
                )}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="regular16">Subscription</Typography>
            <Typography variant="semiBold16">
              {calculatePlan(customerDetails) === Plan.Free ? (
                <YellowText>{calculatePlan(customerDetails)}</YellowText>
              ) : (
                <GreenText>{calculatePlan(customerDetails)}</GreenText>
              )}
            </Typography>
          </ListItem>
        </ul>
      </Container>
      <Container>
        <Title variant="semiBold18">Payment history</Title>
        {customerPayments.length ? (
          <ul>
            {customerPayments.map((payment, index) => (
              <ListItem key={index}>
                <Typography variant="regular16">
                  {getPaymentType(payment.type)}
                </Typography>
                <Typography variant="semiBold16">
                  {formatISODate(payment.date, DATE_WITH_TIME_FORMAT)}
                </Typography>
              </ListItem>
            ))}
          </ul>
        ) : (
          <Typography variant="regular16">
            This customer haven&apos;t done any payments yet
          </Typography>
        )}
      </Container>
    </SidePanel>
  );
};
