import { createEffect, createEvent, createStore } from "effector";

import { api } from "shared/api";
import { Manager } from "shared/types/manager";

export const markUserForAssignation = createEvent<Manager>();
export const cancelUserAssignation = createEvent();
export const showSuccessMessage = createEvent();

export const $userMarkedForAssignation = createStore<Manager | null>(null);
export const $isAssignUserModalVisible = $userMarkedForAssignation.map(Boolean);
export const $isSuccessMessageVisible = createStore(false);
export const assignUserAsAdminFx = createEffect<string[], any>();

assignUserAsAdminFx.use(api.assignUserAsAdmin);

$userMarkedForAssignation
  .on(markUserForAssignation, (_, user) => user)
  .reset(cancelUserAssignation);

$isSuccessMessageVisible
  .on(assignUserAsAdminFx.done, () => true)
  .reset(cancelUserAssignation);
