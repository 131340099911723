import { transparentize } from "polished";
import styled from "styled-components";

export const Input = styled.input<{ error?: boolean }>`
  padding: 16px;
  height: 52px;
  width: 100%;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */
  font-feature-settings: "pnum" on, "lnum" on, "ss09" on;
  color: ${({ theme }) => theme.colors.input};
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ error, theme }) =>
    error ? theme.colors.error : theme.colors.input};
  border-radius: ${({ theme }) => theme.controlBorderRadius};
  box-sizing: border-box;

  &::placeholder {
    color: ${({ theme }) => transparentize(0.7, theme.colors.input)};
  }

  & path {
    fill: ${({ theme }) => theme.colors.input};
    stroke: ${({ theme }) => theme.colors.input};
  }

  &:focus {
    color: ${({ theme }) => theme.colors.text};
    border-color: ${({ theme }) => theme.colors.text};
    outline: none;

    & path {
      fill: ${({ theme }) => theme.colors.text};
      stroke: ${({ theme }) => theme.colors.text};
    }
  }

  /* https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/ */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.input};
    transition: background-color 50000s ease-in-out 0s;
  }
`;
