import { useStore } from "effector-react";
import styled from "styled-components";

import star from "shared/images/star.png";
import trophy from "shared/images/trophy.png";
import { Horse } from "shared/types/races";
import { Typography } from "shared/ui/Typography";

import { findHorseNumber, findJokeyName } from "../lib";
import { $activePicks, $sortedPicks } from "../model";

const Title = styled(Typography)`
  margin-bottom: 50px;
  display: block;
`;

const Table = styled.table`
  width: 100%;
  margin-bottom: 50px;
`;

const TableRow = styled.tr<{ isScratched?: boolean }>`
  justify-content: space-between;
  display: flex;
  border-bottom: 1px solid #1f2340;
  padding: 20px 0;
  position: relative;
  alignitems: "center";
  ${({ isScratched, theme }) =>
    isScratched &&
    `&:after {
      content: "Scratched";
      position: absolute;
      left: -42px;
      top: 0;
      right: -42px;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.colors.error};
      font-weight: 600;
      font-size: 16px;
      background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(16,24,29,0.9) 42px, rgba(16,24,29,0.9) calc(100% - 42px), rgba(16,24,29,0) 100%);
    }`};
`;

const TableHead = styled.thead`
  & tr {
    padding-top: 0;
  }
`;

const TableHeadCell = styled.th`
  padding: 0;
  font-weight: 600;
  font-size: 18px;
  overflow-wrap: break-word;
`;

const TableCell = styled.td`
  font-size: 16px;
`;

type Props = {
  horses: Horse[];
};

export const HorsesInfo: React.FC<Props> = ({ horses }) => {
  const activePicks = useStore($activePicks);
  const sortedPicks = useStore($sortedPicks);

  return (
    <>
      {!!activePicks?.length && (
        <>
          <Title variant="bold20">Picks</Title>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell>Runner</TableHeadCell>
                <TableHeadCell>Jockey</TableHeadCell>
              </TableRow>
            </TableHead>
            <tbody>
              {sortedPicks?.map(({ horseName, type, scratched }) => (
                <TableRow key={horseName} isScratched={scratched}>
                  <TableCell>
                    {findHorseNumber(horses, horseName)}. {horseName}{" "}
                    {type === "WIN" ? (
                      <img src={trophy} alt="trophy" />
                    ) : (
                      <img src={star} alt="star" />
                    )}
                  </TableCell>
                  <TableCell>{findJokeyName(horses, horseName)}</TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </>
      )}
      <Title variant="bold20">Form</Title>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>Runner</TableHeadCell>
            <TableHeadCell>Jockey/Trainer</TableHeadCell>
          </TableRow>
        </TableHead>
        <tbody>
          {horses?.map(
            ({ name, listNumber, jockey, scratched, trainerName }) => (
              <TableRow
                style={{ alignItems: "center" }}
                key={`${name}${listNumber}${jockey}`}
                isScratched={scratched}
              >
                <TableCell>{`${listNumber}. ${name}`}</TableCell>
                <TableCell
                  style={{
                    display: "flex",

                    flexDirection: "column",
                    gap: 5,
                    alignItems: "flex-end",
                  }}
                >
                  <TableCell>J: {jockey}</TableCell>
                  <TableCell>T: {trainerName}</TableCell>
                </TableCell>
              </TableRow>
            )
          )}
        </tbody>
      </Table>
    </>
  );
};
