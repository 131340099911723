export const PlusIcon: React.FC = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.93668 6.70434C1.64786 6.71138 1.40801 6.95123 1.40097 7.24005C1.39392 7.52888 1.62235 7.7573 1.91117 7.75026L6.35632 7.64184L6.24791 12.087C6.24086 12.3758 6.46929 12.6042 6.75811 12.5972C7.04693 12.5902 7.28678 12.3503 7.29382 12.0615L7.40224 7.61633L11.8474 7.50791C12.1362 7.50087 12.3761 7.26102 12.3831 6.9722C12.3902 6.68337 12.1617 6.45495 11.8729 6.46199L7.42775 6.57041L7.53617 2.12526C7.54322 1.83644 7.31479 1.60801 7.02597 1.61505C6.73714 1.6221 6.4973 1.86195 6.49025 2.15077L6.38183 6.59592L1.93668 6.70434Z"
      fill="white"
    />
  </svg>
);
