import { useForm } from "react-hook-form";
import styled from "styled-components";

import { Button } from "shared/ui/Button";
import { FormField } from "shared/ui/FormField";
import { PasswordInput } from "shared/ui/PasswordInput";

import { resetPassword } from "./model";

const Container = styled.main`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VersionMark = styled.main`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 10px;
`;

const Form = styled.form`
  padding: 60px 42px 80px 42px;
  width: 428px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.gradients.background};
  border-radius: ${({ theme }) => theme.formBorderRadius};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadow};

  & > input:first-of-type {
    margin-bottom: 16px;
  }
`;

const FormButton = styled(Button)`
  margin-bottom: 24px;
`;

const FormTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 21px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
`;

type PasswordForm = {
  passwordFirst: string;
  passwordSecond: string;
};


const defaultValues: PasswordForm = {
  passwordFirst: "",
  passwordSecond: "",
};

export const NewPasswordPage = () => {

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<PasswordForm>({ defaultValues });

  const passwordFirst = watch("passwordFirst");

  const { ref: firstRef, ...firstProps } = register("passwordFirst", {
    required: "This field is required",
  });

  const { ref: secondRef, ...secondProps } = register("passwordSecond", {
    required: "This field is required",
    validate: {
      notMatch: (value) =>
        value !== passwordFirst ? "Passwords didn't match" : true,
    },
  });

  return (
    <Container>
      <Form onSubmit={handleSubmit(({ passwordFirst }) => resetPassword(passwordFirst))}>
        <FormTitle>Enter new password</FormTitle>
        <FormField error={errors.passwordFirst?.message}>
          <PasswordInput
            placeholder="Password"
            inputRef={firstRef}
            error={!!errors.passwordFirst}
            {...firstProps}
          />
        </FormField>
        <FormField error={errors.passwordSecond?.message}>
          <PasswordInput
            placeholder="Re-enter password"
            inputRef={secondRef}
            error={!!errors.passwordSecond}
            {...secondProps}
          />
        </FormField>
        <FormButton type="submit">Set new password</FormButton>
      </Form>
      <VersionMark>0.1.1</VersionMark>
    </Container>
  );
};

