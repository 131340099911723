import styled from "styled-components";

import { Horse } from "shared/types/races";
import { RadialChart } from "shared/ui/charts";
import { SidePanel } from "shared/ui/SidePanel";
import { Typography } from "shared/ui/Typography";

import { getStatistic } from "../lib";

import { HorsesInfo } from "./HorsesInfo";
import {
  PredictionContainer,
  PredictionLabel,
  WinsPrediction,
} from "./prediction";

const Title = styled(Typography)`
  margin-bottom: 15px;
  display: block;
`;

type Props = {
  horses: Horse[];
  onClose: () => void;
};

export const RaceInfo: React.FC<Props> = ({ horses, onClose }) => {
  const { winPercent } = getStatistic(horses);
  const isStatisticVisible = horses.some(
    (horse) =>
      horse.finishResult !== 0 || horse.betfairWin || horse.betfairPlace
  );

  return (
    <SidePanel onClose={onClose}>
      {isStatisticVisible && (
        <>
          <Title variant="bold20">AI prediction</Title>
          <PredictionContainer>
            <WinsPrediction>
              <RadialChart
                value={winPercent}
                label="Wins"
                color="orange"
                size="large"
              />
              <PredictionLabel variant="semiBold16">
                Pick prediction
              </PredictionLabel>
            </WinsPrediction>
            {/* <PlacesPrediction>
              <RadialChart
                value={placePercent}
                label="Places"
                color="yellow"
                size="small"
              />
              <PredictionLabel variant="regular16">
                Place prediction
              </PredictionLabel>
            </PlacesPrediction> */}
          </PredictionContainer>
        </>
      )}
      <HorsesInfo horses={horses} />
    </SidePanel>
  );
};
