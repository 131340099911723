import { createEffect, sample } from "effector";

import { api } from "shared/api";

import { update } from "pages/Customers/model";

export const activateCustomerFx = createEffect(api.activateCustomer);
export const deactivateCustomerFx = createEffect(api.deactivateCustomer);

sample({
  clock: activateCustomerFx.done,
  target: update
});

sample({
  clock: deactivateCustomerFx.done,
  target: update
});

