import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 245px;
  margin-top: -20px;
`;

type Props = {
  value: number;
  label: string;
  valueFormatter?: (val: number) => string;
};

export const SemiCircleChart: React.FC<Props> = ({
  value,
  label,
  valueFormatter = (val) => `${val}%`,
}) => {
  const config: ApexOptions = {
    grid: {
      padding: {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
      },
    },
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -85,
        endAngle: 85,
        hollow: {
          size: "70%",
        },
        track: {
          background: "rgba(255, 255, 255, 0.4)",
          margin: 0,
        },

        dataLabels: {
          name: {
            color: "#ffffff",
            fontWeight: 600,
            fontSize: "18px",
            offsetY: -5,
          },
          value: {
            fontFamily: "'Inter', sans-serif;",
            fontSize: "36px",
            fontWeight: 600,
            color: "#ffffff",
            formatter: valueFormatter,
            offsetY: -55,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      colors: ["#05AC5C"],
      gradient: {
        shade: "dark",
        type: "diagonal2",
        shadeIntensity: 0.5,
        gradientToColors: ["#05FE86"],
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: [label],
  };

  return (
    <Container>
      <ReactApexChart
        options={config}
        series={[value]}
        type="radialBar"
        width="570"
      />
    </Container>
  );
};
