import { useGate, useStore } from "effector-react";

import { formatISODate } from "shared/lib/date";
import { SHORT_DATE_FORMAT } from "shared/lib/date";
import { formatMoney } from "shared/lib/formatMoney";
import { getFullName } from "shared/lib/getFullName";
import { Paging } from "shared/paging";
import { Loader } from "shared/ui/Loader";
import { PageLayout } from "shared/ui/PageLayout";
import {
  EmptyTablePlaceholder,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "shared/ui/table";
import { Typography } from "shared/ui/Typography";
import { Underlined } from "shared/ui/Underlined";

import { CustomerInfo, customerInfoModel } from "features/customerInfo";

import { Statistics } from "./ui/Statistics";
import {
  $data,
  $isEmpty,
  changePageSize,
  loadNext,
  loadPaymentsStatisticFx,
  loadPrev,
  PaymentsGate,
} from "./model";

export const PaymentsPage: React.FC = () => {
  useGate(PaymentsGate);

  const { data, ...paging } = useStore($data);
  const activeCustomerId = useStore(customerInfoModel.$customerId);
  const isEmpty = useStore($isEmpty);
  const isCustomerInfoVisible = useStore(
    customerInfoModel.$isCustomerInfoVisible
  );

  const selectCustomer = (customerId: string) =>
    customerInfoModel.showCustomerInfo(customerId);
  const loading = useStore(loadPaymentsStatisticFx.pending);
  if (loading) {
    return <Loader sideWidth="238px" />;
  }
  return (
    <>
      <PageLayout
        titleComponent={
          <Typography variant="bold20">
            <Underlined>Payments</Underlined>
          </Typography>
        }
      >
        <Table striped withArrows={!isEmpty}>
          <TableHead>
            <TableRow>
              <TableHeadCell>Username</TableHeadCell>
              <TableHeadCell>Last Payments</TableHeadCell>
              <TableHeadCell>Payment amount</TableHeadCell>
              <TableHeadCell>Total Spent</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isEmpty && (
              <EmptyTablePlaceholder>
                You have no Payments yet
              </EmptyTablePlaceholder>
            )}
            {data.map((customer) => (
              <TableRow
                key={customer.id}
                onClick={() => selectCustomer(customer.id)}
                clickable
                isActive={customer.id === activeCustomerId}
              >
                <TableCell>{getFullName(customer)}</TableCell>
                <TableCell>
                  <Typography variant="regular16">
                    {customer?.lastPaymentAt &&
                      formatISODate(customer.lastPaymentAt, SHORT_DATE_FORMAT)}
                  </Typography>
                </TableCell>
                <TableCell>
                  {customer?.lastPayment && formatMoney(customer.lastPayment)}
                </TableCell>
                <TableCell>
                  {customer?.paymentTotal && formatMoney(customer.paymentTotal)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Paging
          {...paging}
          loadNext={loadNext}
          loadPrev={loadPrev}
          changePageSize={changePageSize}
        />
      </PageLayout>
      {isCustomerInfoVisible ? <CustomerInfo /> : <Statistics />}
    </>
  );
};
