import {
  addMinutes,
  formatDistance,
  isBefore,
  parseISO,
} from "date-fns";
import { sortBy } from "lodash";
import moment from "moment";

import { Horse, Pick, Race, Region, Results } from "shared/types/races";

import { Day, RaceIdentifier } from "./types";

import "moment-timezone";

export const checkIsRaceActive = (
  raceIdentifier: RaceIdentifier,
  activeRaceIdentifier: RaceIdentifier | null
) => {
  if (!activeRaceIdentifier) return false;

  return (
    raceIdentifier.type === activeRaceIdentifier.type &&
    raceIdentifier.region === activeRaceIdentifier.region &&
    raceIdentifier.track === activeRaceIdentifier.track &&
    raceIdentifier.race === activeRaceIdentifier.race
  );
};

export const getHorses = (
  activeRaceIdentifier: RaceIdentifier | null,
  regions: Region[]
) => {
  // prettier-ignore
  const foundHorses = regions
    .find((region) => region.state === activeRaceIdentifier?.region)?.tracks
    .find((track) => track.name === activeRaceIdentifier?.track)?.races
    .find((race) => race.number === activeRaceIdentifier?.race)?.horses;

  return foundHorses || [];
};

export const getPickIdentifier = (pick: Pick) =>
  `${pick.trackName}:${pick.raceNumber}`;

export const getRaceIdentifier = (race: RaceIdentifier | null) =>
  `${race?.track}:${race?.race}`;

export const getRacesResult = (results: Results | null) =>
  results?.tracks.reduce(
    (trackResults, track, index) => {
      if (index === results?.tracks.length - 1) {
        return {
          ...trackResults,
          wins: Math.floor(trackResults.wins / index),
          places: Math.floor(trackResults.places / index),
        };
      }

      return {
        picksPredicted: trackResults.picksPredicted + track.picksPredicted,
        races: trackResults.races + track.races,
        wins: trackResults.wins + track.wins,
        places: trackResults.places + track.places,
      };
    },
    {
      picksPredicted: 0,
      races: 0,
      wins: 0,
      places: 0,
    }
  );

export const findHorseNumber = (horses: Horse[], horseName: string) =>
  horses.find((horse) => horse.name === horseName)?.listNumber || 0;

export const findJokeyName = (horses: Horse[], horseName: string) =>
  horses.find((horse) => horse.name === horseName)?.jockey || "";

export const sortPicksByRaces = (
  races: Region[],
  picks: Pick[],
  race: RaceIdentifier | null
) =>
  sortBy(
    picks,
    (pick) =>
      getHorses(race, races).find((horse) => horse.name === pick.horseName)
        ?.listNumber
  );

export const getRaceStatus = (race: Race) => {
  const now = new Date();
  const startDateParsed = parseISO(race.startTime);
  const finishDate = addMinutes(startDateParsed, 20);

  if (isBefore(startDateParsed, now)) {
    if (
      race.horses.some((h) => !!h.finishResult) ||
      isBefore(finishDate, now)
    ) {
      return "Finished";
    }

    return "Started";
  }

  return `Starts in ${formatDistance(startDateParsed, now)}`;
};

export const API_FORMAT = "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]";

export const getDayInterval = (day: Day) => {
  let momentDate;

  if (day === "today") {
    momentDate = moment().tz("Australia/Sydney");
  } else {
    momentDate = moment().subtract(1, "days").tz("Australia/Sydney");
  }

  return `${momentDate.startOf("day").format(API_FORMAT)}~${momentDate.endOf("day").format(API_FORMAT)}`;
};

export const getStatistic = (horses: Horse[]) => {
  let winPredictions = 0;
  let correctWinPredictions = 0;
  let placePredictions = 0;
  let correctPlacePredictions = 0;

  for (const horse of horses) {
    if (horse.winSuggestion) winPredictions += 1;
    if (horse.winSuggestion && horse.finishResult === horse.winOrder)
      correctWinPredictions += 1;

    if (horse.placeSuggestion) placePredictions += 1;
    if (horse.placeSuggestion) correctPlacePredictions += 1;
  }
  return {
    winPercent: Math.floor((correctWinPredictions / winPredictions || 0) * 100),
    placePercent: Math.floor(
      (correctPlacePredictions / placePredictions || 0) * 100
    ),
  };
};
