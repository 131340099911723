import styled from "styled-components";

export const Underlined = styled.span`
  position: relative;

  &:after {
    content: "";
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 4px;
    left: 0;
    bottom: -8px;
    border-radius: 25px;
    background: ${({ theme }) => theme.colors.active};
  }
`;
