import styled from "styled-components";

import { Typography } from "shared/ui/Typography";

const Head = styled.div`
  padding: 20px 0;
  padding-left: 36px;
  background: ${({ theme }) => theme.gradients.activeRace};
`;

const RegionName = styled(Typography)`
  font-weight: 600;
  font-size: 24px;
`;

type Props = {
  name: string;
};

export const Region: React.FC<Props> = ({ name, children }) => {
  return (
    <div>
      <Head>
        <RegionName variant="bold20">{name}</RegionName>
      </Head>
      {children}
    </div>
  );
};
