import { useStore } from "effector-react";
import styled from "styled-components";

import { Button } from "shared/ui/Button";
import { Loader } from "shared/ui/Loader";
import { Modal } from "shared/ui/Modal";
import { OutlinedButton } from "shared/ui/OutlinedButton";
import { Typography } from "shared/ui/Typography";

import {
  $customerMarkedForDeletion,
  $isDeleteCustomerModalVisible,
  $isDeleting,
  $isSuccessMessageVisible,
  cancelCustomerDeletion,
  deleteCustomer,
} from "./model";

const ModalButton = styled(Button)`
position: relative;
  margin-top: 18px;
  margin-bottom: 16px;
`;

const NextButton = styled(Button)`
  margin-top: 18px;
`;

export const DeleteCustomerModal: React.FC = () => {
  const isVisible = useStore($isDeleteCustomerModalVisible);
  const customer = useStore($customerMarkedForDeletion);
  const isSuccess = useStore($isSuccessMessageVisible);
  const isDeleting = useStore($isDeleting);
  const handleClose = () => cancelCustomerDeletion();
  const handleDeleteUser = () => {
    if (customer) deleteCustomer(customer);
  };

  return (
    <Modal isOpen={isVisible} onClose={handleClose}>

      {isSuccess ? (
        <>
          <Typography variant="bold20">
            User {customer?.firstName} {customer?.lastName} has successfully been
            deleted
          </Typography>

          <NextButton onClick={handleClose}>Continue</NextButton>
        </>
      ) : (
        <>
          <Typography variant="bold20">
            Delete user {customer?.firstName} {customer?.lastName}?
          </Typography>
          <ModalButton disabled={isDeleting} onClick={handleDeleteUser}>{isDeleting && <Loader width="100%" height={"100%"} />} Yes</ModalButton>
          <OutlinedButton disabled={isDeleting} onClick={handleClose}>No</OutlinedButton>
        </>
      )}
    </Modal>
  );
};
