export type Customer = Readonly<{
  id: string;
  email: string;
  createdAt: string;
  firstName: string;
  lastName: string | null;
  isActiveByCoupon: boolean;
  isActive: boolean;
  hasTrialSubscription: boolean;
  isActiveSubscription: boolean;
  lastPaymentAt: string | null;
  ticketsBalance: number;
  paymentTotal: Money & {
    map: {
      // Column: {};
    };
  };
  lastPayment: Money & {
    map: {
      // Column: {};
    };
  };
  paymentsCount: number;
  registrationStep: RegistrationStep;
}>;

export enum Plan {
  Monthly = "Monthly",
  Free = "Pay Per Race",
  Trial = "Free Trial",
  ActivatedByAdmin = "Free Access",
}

export enum RegistrationStep {
  Completed = "COMPLETED",
  ChoosePlan = "CHOOSE_PLAN",
}

export type CustomerDetails = Readonly<{
  id: string;
  email: string;
  firstName: string;
  lastName: string | null;
  firstAddress: string | null;
  secondAddress: string | null;
  dayOfRegistration: string;
  isActiveSubscription: boolean;
  isActive: boolean;
  hasTrialSubscription: boolean;
  ticketsBalance: number;
  postcode: string | null;
  state: string | null;
  suburb: string | null;
}>;

export type PaymentType =
  | "PLAN_ACQUISITION"
  | "PLAN_EXTENSION"
  | "PLAN_TRIAL"
  | "PURCHASE";

export type CustomerPayment = Readonly<{
  date: string;
  type: PaymentType;
}>;
