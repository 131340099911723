import { SVGProps } from "react";

export const CrossIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  width = 10,
  height = 10,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.07332 8.8029C0.854466 9.02543 0.854466 9.38622 1.07332 9.60875C1.29218 9.83128 1.64702 9.83128 1.86588 9.60875L5.23423 6.1839L8.60259 9.60875C8.82145 9.83128 9.17629 9.83128 9.39515 9.60875C9.614 9.38622 9.614 9.02543 9.39515 8.8029L6.02679 5.37806L9.39515 1.95321C9.614 1.73068 9.614 1.36989 9.39515 1.14736C9.17629 0.924836 8.82145 0.924837 8.60259 1.14737L5.23423 4.57221L1.86588 1.14737C1.64702 0.924837 1.29218 0.924836 1.07332 1.14736C0.854465 1.36989 0.854465 1.73068 1.07332 1.95321L4.44168 5.37806L1.07332 8.8029Z"
      fill="white"
    />
  </svg>
);
