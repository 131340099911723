import axios from "axios";

const baseURL = window.location.origin;
export const http = axios.create({ baseURL, withCredentials: true, headers: { Accept: "application/json", "Content-Type": "application/json" }});

export const setToken = (token: string): void => {
  http.defaults.headers.common.Authorization = `Bearer ${token}`;
};

let isRefreshing = false;
const subscribers: ((token: string) => void)[] = [];

const onRefreshed = (token: string) => {
  subscribers.forEach((callback) => callback(token));
  subscribers.length = 0;
};

const subscribeTokenRefresh = (callback: (token: string) => void) => {
  subscribers.push(callback);
};
const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("REFRESH_TOKEN");
  if (!refreshToken) {
    localStorage.removeItem("REFRESH_TOKEN");
    localStorage.removeItem("ACCESS_TOKEN");
  }

  const response = await http.post("api/authentication/refresh-access-token", {
    token: refreshToken,
  });
  const { accessToken, refreshToken: newRefreshToken } = response.data.data;
  
  localStorage.setItem("REFRESH_TOKEN", newRefreshToken);
  setToken(accessToken);
  localStorage.setItem("ACCESS_TOKEN",accessToken);
  return accessToken;
};

http.interceptors.response.use(
  (response) => {
    if (response.status >= 500) {
      console.log("Server glitched. We're resolving it now!");
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.message === "You're not authorized" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise((resolve) => {
          subscribeTokenRefresh((token: string) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(http(originalRequest));
          });
        });
      }

      isRefreshing = true;
      try {
        const newAccessToken = await refreshAccessToken();
        isRefreshing = false;
        onRefreshed(newAccessToken);
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return http(originalRequest);
      } catch (refreshError) {
        isRefreshing = false;
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  },
);
