import { useStore } from "effector-react";
import styled from "styled-components";

import { formatMoney } from "shared/lib/formatMoney";
import { RadialChart, SemiCircleChart } from "shared/ui/charts";
import { SidePanel } from "shared/ui/SidePanel";
import { Typography } from "shared/ui/Typography";

import { $paymentsStatistic, $revenue } from "../model";

const Title = styled(Typography)`
  margin-bottom: 15px;
  display: block;
  text-transform: capitalize;
`;

const Line = styled.div`
  border-bottom: 1px solid #1f2340;
  margin: 64px 0;
`;

const ActivityContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -75px;
`;

const ActivityLabel = styled(Typography)`
  margin-left: -50px;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.4);
`;

const BoldActivityLabel = styled.span`
  font-weight: 600;
  color: rgb(255, 255, 255);
  margin-left: 5px;
`;

export const Statistics: React.FC = () => {
  const revenue = useStore($revenue);
  const paymentsStatistic = useStore($paymentsStatistic);

  if (!revenue || !paymentsStatistic) return <SidePanel />;

  const paidPlans =
    paymentsStatistic.subscribedCustomers / paymentsStatistic.totalCustomers ||
    0;
  const paidPlansDet =
    paidPlans < 1
      ? Number((paidPlans * 100).toFixed(2))
      : Math.round(paidPlans) * 100;

  return (
    <SidePanel background>
      <Title variant="bold20">Total statistics</Title>
      <SemiCircleChart
        value={revenue.percentageOfMaxRevenue}
        label="Monthly revenue"
        valueFormatter={() => formatMoney(revenue.monthlyRevenue[0])}
      />
      <Line />
      <Title variant="bold20">Users activity</Title>
      <ActivityContainer>
        <RadialChart
          value={paidPlansDet}
          label="Paid plans"
          color="yellow"
          size="large"
        />
        <ActivityLabel variant="regular16">
          Subscribed:
          <BoldActivityLabel>
            {paymentsStatistic.subscribedCustomers}
          </BoldActivityLabel>
        </ActivityLabel>
      </ActivityContainer>
    </SidePanel>
  );
};
