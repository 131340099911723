import { createEffect, createEvent, createStore } from "effector";

import { api } from "shared/api";
import { Manager } from "shared/types/manager";

export const markUserForDeletion = createEvent<Manager>();
export const cancelUserDeletion = createEvent();

export const $userMarkedForDeletion = createStore<Manager | null>(null);
export const $isDeleteUserModalVisible = $userMarkedForDeletion.map(Boolean);
export const $isSuccessMessageVisible = createStore(false);

export const deleteUserFx = createEffect<string[], any>();

deleteUserFx.use(api.deleteUser);

$userMarkedForDeletion
  .on(markUserForDeletion, (_, user) => user)
  .reset(cancelUserDeletion);

$isSuccessMessageVisible
  .on(deleteUserFx.done, () => true)
  .reset(cancelUserDeletion);
