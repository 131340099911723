import styled from "styled-components";

interface Props {
  color: "green" | "yellow";
}

const Green = styled.span`
  color: ${({ theme }) => theme.colors.active};
`;

const Yellow = styled.span`
  background: ${({ theme }) => theme.gradients.yellow};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Colored: React.FC<Props> = ({ color, children }) =>
  color === "green" ? <Green>{children}</Green> : <Yellow>{children}</Yellow>;
