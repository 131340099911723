import styled from "styled-components";

import horses from "shared/images/horses.png";
import { CrossIcon } from "shared/ui/icons/Cross";

import { Typography } from "./Typography";

const Container = styled.aside<{ withBackground?: boolean }>`
  width: 470px;
  background-color: ${({ theme }) => theme.colors.darkBackground};
  padding-top: 60px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ withBackground }) =>
    withBackground
      ? `
        background-image: url(${horses});
        background-position: bottom right;
        background-repeat: no-repeat;
        `
      : ""}
`;

const Close = styled.div`
  cursor: pointer;
  margin-right: 42px;
`;

const Head = styled.div`
  padding-left: 42px;
  margin-bottom: 15px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(Typography)`
  flex-grow: 1;
`;

const SidePanelContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 42px 20px;
`;

type Props = {
  title?: string;
  background?: boolean;
  onClose?: () => void;
};

export const SidePanel: React.FC<Props> = ({
  title = "",
  onClose,
  background = false,
  children,
}) => (
  <Container withBackground={background}>
    {onClose && (
      <Head>
        <Title variant="bold20">{title}</Title>
        <Close onClick={onClose}>
          <CrossIcon width={16} height={16} />
        </Close>
      </Head>
    )}
    <SidePanelContainer>{children}</SidePanelContainer>
  </Container>
);
