import styled from "styled-components";

export const OutlinedButton = styled.button`
  height: 54px;
  width: 100%;
  color: ${({ theme }) => theme.colors.text};
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.controlBorderRadius};
  box-shadow: ${({ theme }) => theme.shadow};
  background-size: 0 0;
  position: relative;
  background-image: linear-gradient(93.96deg, #2f05d7 -15.41%, #69f6ff 178.05%);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    -webkit-mask: url('data:image/svg+xml;utf8,<svg  xmlns="http://www.w3.org/2000/svg" ><rect x="1" y="1" width="100%" height="100%" style="height:calc(100% - 2px);width:calc(100% - 2px)" rx="15" ry="15" stroke-width="1" fill="transparent" stroke="white"/></svg>')
      0 / 100% 100%;
    mask: url('data:image/svg+xml;utf8,<svg  xmlns="http://www.w3.org/2000/svg" ><rect x="1" y="1" width="100%" height="100%" style="height:calc(100% - 2px);width:calc(100% - 2px)" rx="15" ry="15" stroke-width="1" fill="transparent" stroke="white"/></svg>')
      0 / 100% 100%;
  }

  &:hover {
    opacity: 0.8;
  }
`;
