import { Plan } from "shared/types/customer";

type CustomerSubscriptionDetails = {
  isActive: boolean;
  isActiveSubscription: boolean;
  hasTrialSubscription: boolean;
};

export const calculatePlan = (customer: CustomerSubscriptionDetails) => {
  if (customer.isActive) return Plan.ActivatedByAdmin;
  if (customer.isActiveSubscription && customer.hasTrialSubscription)
    return Plan.Trial;
  if (customer.isActiveSubscription && !customer.hasTrialSubscription)
    return Plan.Monthly;

  return Plan.Free;
};
