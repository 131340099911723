import styled from "styled-components";

import { Statistic } from "shared/types/races";
import { RadialChart, SemiCircleChart } from "shared/ui/charts";
import { SidePanel } from "shared/ui/SidePanel";
import { Typography } from "shared/ui/Typography";

import { Line } from "./Line";
import {
  BoldLabel,
  PlacesPrediction,
  PredictionContainer,
  PredictionLabel,
  WinsPrediction,
} from "./prediction";

const Title = styled(Typography)`
  display: block;
  text-transform: capitalize;
`;

type Props = {
  statistic: Statistic | null;
};

export const Statistics: React.FC<Props> = ({ statistic }) => (
  <SidePanel background>
    <Title variant="bold20">statistics</Title>
    <SemiCircleChart
      value={statistic?.overall || 50}
      label="Average win & place rate"
    />
    <Line />
    <Typography variant="bold20">AI prediction</Typography>
    <PredictionContainer>
      <WinsPrediction>
        <RadialChart
          value={statistic?.winsPercentage || 0}
          label="Wins"
          color="orange"
          size="small"
        />
        <PredictionLabel variant="regular16">
          Races: <BoldLabel>{statistic?.races || 0}</BoldLabel>
        </PredictionLabel>
      </WinsPrediction>
      <PlacesPrediction>
        <RadialChart
          value={statistic?.placesPercentage || 0}
          label="Places"
          color="yellow"
          size="small"
        />
        <PredictionLabel variant="regular16">
          Picks predicted:{" "}
          <BoldLabel>{statistic?.picksPredicted || 0}</BoldLabel>
        </PredictionLabel>
      </PlacesPrediction>
    </PredictionContainer>
  </SidePanel>
);
