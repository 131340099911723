import { ContentRenderer, Popover as ReactPopover } from "react-tiny-popover";
import styled from "styled-components";

import { CrossIcon } from "shared/ui/icons/Cross";

const PopoverComponent = styled.div`
  margin-right: -5px;
  background: #030a23;
  border-radius: 20px 10px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 24px;
`;

const ClosePopover = styled.button`
  align-self: flex-end;
  margin: 15px 15px 5px 0;
`;

interface Props {
  onClose: () => void;
  isOpen: boolean;
  content: JSX.Element | ContentRenderer;
  children: any;
}

export const Popover: React.FC<Props> = ({
  onClose,
  isOpen,
  content,
  children,
}) => (
  <ReactPopover
    isOpen={isOpen}
    positions={["bottom", "top"]}
    align="end"
    padding={5}
    onClickOutside={onClose}
    content={() => (
      <PopoverComponent onClick={(e) => e.stopPropagation()}>
        <ClosePopover type="button" onClick={onClose}>
          <CrossIcon />
        </ClosePopover>
        {content}
      </PopoverComponent>
    )}
  >
    {children}
  </ReactPopover>
);
