import { AxiosError } from "axios";
import { createEffect, createEvent, createStore, sample } from "effector";

import { history } from "router/history";
import { api, ResetPasswordConfirm, ResetPasswordNewPassword, ResetPasswordRequest } from "shared/api";

export type ServerCommonError = {
  error: {
    message: string;
  }
};

export const resetPasswordRequestFx = createEffect<ResetPasswordRequest, any, AxiosError<FormErrorResponse>>(api.resetPasswordRequest);

export const resetPasswordConfirmFx = createEffect<ResetPasswordConfirm, any, AxiosError<ServerCommonError>>(api.resetPasswordConfirm);

export const resetPasswordNewPasswordFx = createEffect<ResetPasswordNewPassword, any, AxiosError<FormErrorResponse>>(api.resetPasswordNewPassword);

export const resetPassword = createEvent<string>();
export const resetConfirmCodeResetPasswordErrors = createEvent();

export const $email = createStore("");
export const $code = createStore("");
export const $newPassword = createStore("");

export const $resetPasswordRequestErrors = createStore<ServerFormError[]>([])
  .on(resetPasswordRequestFx.failData, (_, { response }) => response?.data.errors || [])
  .reset(resetPasswordRequestFx.done);

export const $resetPasswordConfirmErrors = createStore<string>("")
  .on(resetPasswordConfirmFx.failData, (_, { response }) => {
    return response?.data.error.message;
  })
  .reset([resetPasswordConfirmFx.done, resetConfirmCodeResetPasswordErrors]);

sample({
  clock: resetPasswordRequestFx.done,
  fn: ({ params }) => params.email,
  target: $email,
});

sample({
  clock: resetPasswordConfirmFx.done,
  fn: ({ params }) => `${params.token}`,
  target: $code,
});

sample({
  source: $code,
  clock: resetPassword,
  fn: (token, newPassword) => ({ token, newPassword }),
  target: resetPasswordNewPasswordFx,
});

sample({
  clock: resetPasswordNewPasswordFx.done,
  fn: ({ params }) => params.newPassword,
  target: $newPassword,
});

resetPasswordRequestFx.done.watch(() => {
  history.push("/resetCode");
});
resetPasswordConfirmFx.done.watch(() => {
  history.push("/newPassword");
});
resetPasswordNewPasswordFx.done.watch(() => {
  history.push("/login");
});
