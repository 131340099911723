import { useStore } from "effector-react";
import styled from "styled-components";

import { Button } from "shared/ui/Button";
import { Modal } from "shared/ui/Modal";
import { OutlinedButton } from "shared/ui/OutlinedButton";
import { Typography } from "shared/ui/Typography";

import {
  $isRemoveUserModalVisible,
  $isSuccessMessageVisible,
  $userMarkedForRemoving,
  cancelUserRemoving,
  removeUserFromAdminsFx,
} from "./model";

const ModalButton = styled(Button)`
  margin-top: 18px;
  margin-bottom: 16px;
`;

const NextButton = styled(Button)`
  margin-top: 18px;
`;

export const RemoveUserFromAdminsModal: React.FC = () => {
  const isVisible = useStore($isRemoveUserModalVisible);
  const user = useStore($userMarkedForRemoving);
  const isSuccess = useStore($isSuccessMessageVisible);

  const handleClose = () => cancelUserRemoving();

  const handleRemoveUserFromAdmins = () => {
    if (user) {
      removeUserFromAdminsFx([user.id]);
    }
  };

  return (
    <Modal isOpen={isVisible} onClose={handleClose}>
      {isSuccess ? (
        <>
          <Typography variant="bold20">
            User {user?.firstName} {user?.lastName} has successfully been
            removed from Admins
          </Typography>
          <NextButton onClick={handleClose}>Continue</NextButton>
        </>
      ) : (
        <>
          <Typography variant="bold20">
            Remove {user?.firstName} {user?.lastName} from Admins?
          </Typography>
          <ModalButton onClick={handleRemoveUserFromAdmins}>Yes</ModalButton>
          <OutlinedButton onClick={handleClose}>No</OutlinedButton>
        </>
      )}
    </Modal>
  );
};
