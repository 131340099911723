import { createEffect, createEvent, createStore } from "effector";

import { api } from "shared/api";
import { Manager } from "shared/types/manager";

export const markUserForRemoving = createEvent<Manager>();
export const cancelUserRemoving = createEvent();

export const $userMarkedForRemoving = createStore<Manager | null>(null);
export const $isRemoveUserModalVisible = $userMarkedForRemoving.map(Boolean);
export const $isSuccessMessageVisible = createStore(false);
export const removeUserFromAdminsFx = createEffect<string[], any>();

removeUserFromAdminsFx.use(api.removeUserFromAdmins);

$userMarkedForRemoving
  .on(markUserForRemoving, (_, user) => user)
  .reset(cancelUserRemoving);

$isSuccessMessageVisible
  .on(removeUserFromAdminsFx.done, () => true)
  .reset(cancelUserRemoving);
