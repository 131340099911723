import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Placeholder = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
`;

export const EmptyPlaceholder: React.FC = ({ children }) => (
  <Container>
    <Placeholder>{children}</Placeholder>
  </Container>
);
