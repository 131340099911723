import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Link = styled(NavLink)`
  color: #ffffff;
  margin-right: 35px;
  font-weight: 600;
  font-size: 16px;
  opacity: 0.4;
  text-decoration: none;
  display: inline;
  &.active {
    position: relative;
    opacity: 1;
    font-weight: 700;
    font-size: 20px;
    transition: opacity 0.3s linear;
    &:after {
      content: "";
      position: absolute;
      pointer-events: none;
      width: 100%;
      height: 4px;
      left: 0;
      bottom: -8px;
      border-radius: 25px;
      background: ${({ theme }) => theme.colors.active};
    }
  }
`;

type Props = {
  to: string;
  children: string;
};

export const PageLink: React.FC<Props> = ({ to, children }) => {
  return (
    <Link exact to={to}>
      {children}
    </Link>
  );
};
