import styled from "styled-components";

export const PopoverOption = styled.button`
  padding: 12px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #686c7b;

  &:hover {
    background: #2d333a;
    color: white;
  }
`;
