import { useStore } from "effector-react";
import styled from "styled-components";

import { Button } from "shared/ui/Button";
import { Modal } from "shared/ui/Modal";
import { Typography } from "shared/ui/Typography";

import {
  $isCreateUserModalVisible,
  $isSuccessMessageVisible,
  $user,
  cancelUserCreation,
} from "../model";

import { CreateUserForm } from "./CreateUserForm";

const ModalButton = styled(Button)`
  margin-top: 24px;
`;

export const CreateUserModal: React.FC = () => {
  const isOpen = useStore($isCreateUserModalVisible);
  const isSuccess = useStore($isSuccessMessageVisible);
  const user = useStore($user);

  const handleCloseModal = () => cancelUserCreation();

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      {isSuccess ? (
        <>
          <Typography variant="bold20">
            User {user?.firstName} {user?.lastName} has successfully been added
          </Typography>

          <ModalButton onClick={handleCloseModal}>Continue</ModalButton>
        </>
      ) : (
        <CreateUserForm />
      )}
    </Modal>
  );
};
