import styled from "styled-components";

import { Typography } from "./Typography";

const Text = styled(Typography)`
  color: ${({ theme }) => theme.colors.active};
`;

export const GreenText: React.FC = ({ children }) => (
  <Text variant="semiBold16">{children}</Text>
);
