import { useForm } from "react-hook-form";
import { useStore } from "effector-react";
import styled from "styled-components";

import { useServerFormErrors } from "shared/hooks";
import { Button } from "shared/ui/Button";
import { FormField } from "shared/ui/FormField";
import { Input } from "shared/ui/Input";

import { $resetPasswordRequestErrors, resetPasswordRequestFx } from "./model";

const Container = styled.main`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VersionMark = styled.main`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 10px;
`;

const Form = styled.form`
  padding: 60px 42px 80px 42px;
  width: 428px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.gradients.background};
  border-radius: ${({ theme }) => theme.formBorderRadius};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadow};

  & > input:first-of-type {
    margin-bottom: 16px;
  }
`;

const FormButton = styled(Button)`
  margin-bottom: 24px;
`;

const FormTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 21px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
`;

type LoginForm = {
  email: string;
};


const defaultValues: LoginForm = { email: "" };

export const ForgotPasswordPage = () => {

  const emailError = useStore($resetPasswordRequestErrors);

  const {
    register,
    setError,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginForm>({ defaultValues });

  useServerFormErrors(emailError, setError);

  return (
    <Container>
      <Form onSubmit={handleSubmit(resetPasswordRequestFx
      )}>
        <FormTitle>Reset password</FormTitle>
        <FormField error={errors.email?.message}>
          <Input
            placeholder="Enter email"
            type="text"
            error={!!errors.email}
            {...register("email", { required: "Email is required" })}
          />
        </FormField>
        <FormButton type="submit">Reset password</FormButton>
      </Form>
      <VersionMark>0.1.1</VersionMark>
    </Container>
  );
};

