import { useGate, useStore } from "effector-react";
import styled from "styled-components";

import { EmptyPlaceholder } from "shared/ui/EmptyPlaceholder";
import { Loader } from "shared/ui/Loader";

import { checkIsRaceActive } from "../lib";
import {
  $activeRace,
  $isLoaderVisible,
  $races,
  RacesGate,
  setActiveRace,
} from "../model";
import { Day } from "../types";

import { Race } from "./Race";
import { Region as RegionContainer } from "./Region";
import { Track } from "./Track";

const Container = styled.div`
  overflow-y: auto;
  height: calc(100vh - 138px);
`;

type Props = {
  day: Day;
};

export const Races: React.FC<Props> = ({ day }) => {
  const races = useStore($races);
  const activeRace = useStore($activeRace);
  const isLoaderVisible = useStore($isLoaderVisible);

  useGate(RacesGate, { day });

  if (isLoaderVisible) return <Loader />;

  if (!races.length)
    return (
      <Container>
        <EmptyPlaceholder>
          {day === "today"
            ? "There are no today races"
            : "There are no yesterday races"}
        </EmptyPlaceholder>
      </Container>
    );

  return (
    <Container>
      {races.map((region) => (
        <RegionContainer key={region.state} name={region.state}>
          {region.tracks.map((track) => (
            <Track name={track.name} key={track.name}>
              {track.races.map((race) => (
                <Race
                  key={race.number}
                  race={race}
                  track={track.name}
                  type={day}
                  isActive={checkIsRaceActive(
                    {
                      type: day,
                      region: region.state,
                      track: track.name,
                      race: race.number,
                    },
                    activeRace
                  )}
                  onClick={() => {
                    setActiveRace({
                      type: day,
                      region: region.state,
                      track: track.name,
                      race: race.number,
                    });
                  }}
                />
              ))}
            </Track>
          ))}
        </RegionContainer>
      ))}
    </Container>
  );
};
