import { createEffect, createEvent, createStore, forward, sample } from "effector";
import { createGate } from "effector-react";

import { api } from "shared/api";
import { createPageable } from "shared/paging";
import { CustomersAndManagersTotals } from "shared/types/totals";

import { customerInfoModel } from "features/customerInfo";

export const loadTotalsFx = createEffect<void, CustomersAndManagersTotals>(
  api.fetchCustomersAndManagersTotals
);

export const $customersTotal = createStore(0);

export const CustomersGate = createGate();

export const {
  $data,
  $isEmpty,
  $isLoading,
  loadFirst,
  loadNext,
  loadPage,
  loadPrev,
  update,
  changePageSize,
  searchText
} = createPageable(api.fetchCustomers);

$customersTotal.on(loadTotalsFx.doneData, (_, result) => result.customersTotal);

forward({
  from: CustomersGate.open,
  to: [loadFirst, loadTotalsFx],
});

forward({
  from: CustomersGate.close,
  to: customerInfoModel.hideCustomerInfo,
});
export const $isDeleting = createStore(false);
export const deleteCustomer = createEvent<Customer>();
const deleteCustomerFx = createEffect(api.deleteCustomer);
sample({
  clock: deleteCustomer,
  fn: (customer) => customer.id,
  target: deleteCustomerFx,
});

sample({
  clock: deleteCustomerFx.done,
  target: update,
});

$isDeleting.on(deleteCustomer, () => true);
$isDeleting.on(deleteCustomerFx.done, () => false);

export type Customer = {
  id: string;
  firstName: string;
  lastName: string;
};

export const $isDeleteCustomerModalVisible = createStore(false);
export const $isSuccessMessageVisible = createStore(false);
export const markCustomerForDeletion = createEvent<Customer>();
export const cancelCustomerDeletion = createEvent();
export const $customerMarkedForDeletion = createStore<Customer | null>(null);
$customerMarkedForDeletion.on(markCustomerForDeletion, (_, customer) => customer).reset(cancelCustomerDeletion);
$isSuccessMessageVisible.on(deleteCustomerFx.done, () => true).reset(cancelCustomerDeletion);
$isSuccessMessageVisible.on(deleteCustomerFx.fail, () => false).reset(cancelCustomerDeletion);
$isDeleteCustomerModalVisible.on(markCustomerForDeletion, () => true);
$isDeleteCustomerModalVisible.on(cancelCustomerDeletion, () => false);
$isDeleteCustomerModalVisible.on(deleteCustomerFx.fail, () => true);


