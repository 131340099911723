import { createEffect, createEvent, createStore, sample } from "effector";

import { api } from "shared/api";
import { Customer } from "shared/types/customer";

//EFFECTS
export const giveCustomerCreditsFX = createEffect(api.giveCustomerCredits);

// STATES
export const $isGiveCreditsModalVisible = createStore(false);
export const $creditsRecipient = createStore<Customer | null>(null);
export const $successGiveCredits = createStore(false);
export const $failGiveCredits = createStore(false);

// EVENTS
export const showGiveCreditsModal = createEvent();
export const hideGiveCreditsModal = createEvent();
export const setActiveCreditsRecipient = createEvent<Customer>();
export const clearActiveCreditsRecipient = createEvent();

// ACTIONS
$isGiveCreditsModalVisible.on(hideGiveCreditsModal, () => false);
$isGiveCreditsModalVisible.on(showGiveCreditsModal, () => true);
$creditsRecipient.reset(hideGiveCreditsModal);
$successGiveCredits
  .on(giveCustomerCreditsFX.done, () => true)
  .reset([hideGiveCreditsModal, giveCustomerCreditsFX.fail]);
$failGiveCredits
  .on(giveCustomerCreditsFX.fail, () => true)
  .reset([
    hideGiveCreditsModal,
    giveCustomerCreditsFX.done,
    setActiveCreditsRecipient,
  ]);
sample({
  clock: setActiveCreditsRecipient,
  target: showGiveCreditsModal,
});

sample({
  clock: setActiveCreditsRecipient,
  target: $creditsRecipient,
});
