export const RightArrow = () => (
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.21967 0.209209C0.512563 -0.0697365 0.987437 -0.0697365 1.28033 0.209209L5.78033 4.49492C6.07322 4.77387 6.07322 5.22613 5.78033 5.50508L1.28033 9.79079C0.987437 10.0697 0.512563 10.0697 0.21967 9.79079C-0.0732233 9.51184 -0.0732233 9.05958 0.21967 8.78064L4.18934 5L0.21967 1.21936C-0.0732233 0.940416 -0.0732233 0.488155 0.21967 0.209209Z"
      fill="#05fe86"
    />
  </svg>
);
