import styled from "styled-components";

import { Typography } from "shared/ui/Typography";

const Container = styled.div`
  margin-top: 36px;
`;

const Name = styled(Typography)`
  font-weight: bold;
  font-size: 20px;
  padding: 0 36px;
`;

type Props = {
  name: string;
};

export const Track: React.FC<Props> = ({ name, children }) => {
  return (
    <Container>
      <Name variant="bold20">{name}</Name>
      {children}
    </Container>
  );
};
