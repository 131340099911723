import styled from "styled-components";

export const Button = styled.button`
  height: 54px;
  width: 100%;
  color: ${({ theme }) => theme.colors.text};
  text-transform: uppercase;
  background: ${({ theme }) => theme.gradients.button};
  border-radius: ${({ theme }) => theme.controlBorderRadius};
  box-shadow: ${({ theme }) => theme.shadow};

  &:hover {
    opacity: 0.8;
  }
`;
