import Pagination from "rc-pagination/lib/Pagination";
import styled from "styled-components";

import { ArrowRight } from "shared/ui/icons/ArrowRight";

const PaginationWrapper = styled.div`
  .paginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.background};
    color: #7c7987;
    list-style: none;
    > *:not(:last-child) {
      margin-right: 10px;
    }

    .rc-pagination-item {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 50px;
      color: rgba(255, 255, 255, 0.5);
      font-weight: 600;
      cursor: pointer;
      &:not([disabled]):hover {
        background-color: rgba(255, 255, 255, 0.2);
      } 
    }

    .rc-pagination-item-active {
      height: 50px;
      background: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${({ theme }) => theme.colors.text};
      -webkit-appearance: none;
      &:hover {
        background-color: transparent !important;
        cursor: default;
      }
    }

    .rc-pagination-item-disabled {
      &:hover {
        background-color: transparent !important;
        cursor: default;
      }
    },
  }
`;

const PagingContainer = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #565378;
`;

const Select = styled.select`
  padding: 0 10px;
  height: 50px;
  width: 60px;
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  -webkit-appearance: none;
`;

const SelectArrow = styled(ArrowRight)`
  transform: rotate(90deg);
  position: absolute;
  right: 10px;
  pointer-events: none;
`;

const SelectWrapper = styled.span`
  display: flex;
  position: relative;
  align-items: center;
`;

const Button = styled.button`
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  border-radius: 50%;
  &[disabled] {
    opacity: 0.5;
  }
  &:not([disabled]):hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const ArrowLeft = styled(ArrowRight)`
  transform: rotate(180deg);
  `;

const Dots = styled.span`
  cursor : default
  `;

type Props = {
  page: number;
  pageSize: number;
  hasMore: boolean;
  loadPrev: () => void;
  loadNext: () => void;
  loadPage?: (page: number) => void;
  changePageSize: (size: number) => void;
  count: number;
};

const pageSizeOptions = [10, 30, 50];

export const Paging: React.FC<Props> = ({
  pageSize,
  loadPage,
  changePageSize,
  count,
  page,
  hasMore,
  loadNext,
  loadPrev,
}) => {
  const handleChangeSize = (e: React.ChangeEvent<HTMLSelectElement>) =>
    changePageSize(parseInt(e.target.value));

  const paginationWidth = () => {
    const totalPages = Math.ceil(count / pageSize);
    console.log(totalPages);
    if (!count) {
      return { width: "150px" };
    }
    switch (totalPages) {
      case 0:
        return { width: "150px" };
      case 1:
        return { width: "150px" };
      case 2:
        return { width: "250px" };
      case 3:
        return { width: "350px" };
      default:
        return { width: "500px" };
    }
  };

  return (
    <PagingContainer>
      <SelectWrapper>
        <Select value={pageSize} onChange={handleChangeSize}>
          {pageSizeOptions.map((size) => (
            <option value={size} key={size}>
              {size}
            </option>
          ))}
        </Select>
        <SelectArrow />
      </SelectWrapper>
      <PaginationWrapper>
        <Pagination
          itemRender={(current, type, element) => {
            if (type === "jump-prev" || type === "jump-next") {
              return <div onClick={e => e.stopPropagation()}>{element}</div>;
            }
            if (type === "page" && !count) {
              return <div className="rc-pagination-item-active" onClick={e => e.stopPropagation()}>{page}</div>;
            }
            return element;
          }}
          onChange={(page) => loadPage && loadPage(page)}
          current={page}
          total={count}
          pageSize={pageSize}
          className="paginationContainer"

          style={paginationWidth()}
          prevIcon={() => <Button onClick={() => !count && loadPrev()} disabled={page === 1} >
            <ArrowLeft width={8} height={12} />
          </Button>}
          showPrevNextJumpers={true}
          showLessItems={true}
          nextIcon={() => <Button onClick={() => !count && loadNext()} disabled={!hasMore} >
            <ArrowRight width={8} height={12} />
          </Button>}
          jumpNextIcon={<Dots>...</Dots>}
          jumpPrevIcon={<Dots>...</Dots>}
        />
      </PaginationWrapper>
    </PagingContainer>
  );
};
