export const PowerIcon: React.FC = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.693 11.5534V0.886719M4.18337 3.55159C2.44871 5.24515 1.35938 7.5984 1.35938 10.214C1.35938 15.3653 5.53937 19.5534 10.6927 19.5534C15.8487 19.5534 20.026 15.3653 20.026 10.214C20.026 7.6024 18.95 5.24515 17.2194 3.55159"
      stroke="#9FA1DA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
