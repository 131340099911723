import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div<{ sideWidth?: string, height?: string , width?:string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: ${(props) => props.width ? props.width :
   ( props.sideWidth ? `calc(100vw + ${props.sideWidth || "238px"})` : "100%")};
  height: ${(props) => (props.height ? props.height : (props.sideWidth ? "100vh" : "calc(100vh - 138px)"))};
  z-index: 999;
`;

const Chart = styled.div`
  animation: ${rotate} 2s linear infinite;
`;
type Props = {
  sideWidth?: string;
  height?: string;
  width?: string;
};

export const Loader: React.FC<Props> = ({ sideWidth, height, width }) => {
  const config: ApexOptions = {
    grid: {
      padding: {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
      },
    },
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 360,
        hollow: {
          size: "55%",
        },
        track: {
          background: "transparent",
          margin: 0,
        },
        dataLabels: {
          name: {
            color: "#ffffff",
            fontWeight: 600,
            fontSize: "16px",
            offsetY: 30,
          },
          value: {
            fontFamily: "'Inter', sans-serif;",
            fontSize: "36px",
            fontWeight: 600,
            color: "#ffffff",
            formatter: () => "",
            offsetY: -15,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      colors: ["#05fe86"],
      gradient: {
        shade: "dark",
        type: "diagonal2",
        shadeIntensity: 0.5,
        gradientToColors: ["#05fe86"],
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: [""],
  };

  return (
    <Container sideWidth={sideWidth} height={height} width={width}>
      <Chart>
        <ReactApexChart
          options={config}
          series={[80]}
          type="radialBar"
          width="100"
        />
      </Chart>
    </Container>
  );
};
