import React, { useState } from "react";
import styled from "styled-components";

import { EyeClosedIcon } from "./icons/EyeClosed";
import { EyeOpenedIcon } from "./icons/EyeOpened";
import { Input } from "./Input";

const WrappedInput = styled(Input)<{ error?: boolean }>`
  padding-right: 60px;
  border-color: ${({ theme, error }) =>
    error ? theme.colors.error : theme.colors.input};
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  & ${WrappedInput} ~ span svg path {
    stroke: ${({ theme }) => theme.colors.input};
  }

  & ${WrappedInput}:focus ~ span svg path {
    stroke: ${({ theme }) => theme.colors.text};
  }
`;

const ChangeTypeIcon = styled.span`
  position: absolute;
  right: 20px;
  cursor: pointer;
`;

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: boolean;
  inputRef?:
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined;
};

export const PasswordInput: React.FC<Props> = ({
  error,
  inputRef,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleTypeChange = () => setShowPassword((value) => !value);

  return (
    <Wrapper>
      <WrappedInput
        {...props}
        error={error}
        type={showPassword ? "text" : "password"}
        ref={inputRef}
      />
      <ChangeTypeIcon onClick={handleTypeChange}>
        {showPassword ? <EyeOpenedIcon /> : <EyeClosedIcon />}
      </ChangeTypeIcon>
    </Wrapper>
  );
};
