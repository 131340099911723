import styled from "styled-components";

import { Typography } from "./Typography";

const Text = styled(Typography)`
  background: ${({ theme }) => theme.gradients.yellow};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const YellowText: React.FC = ({ children }) => (
  <Text variant="semiBold16">{children}</Text>
);
