import styled from "styled-components";

import { PlusIcon } from "shared/ui/icons/Plus";

import { createUserModel } from "..";

const Button = styled.button`
  display: flex;
  align-items: center;
`;

export const Title = styled.span`
  color: white;
`;

export const BlueCircle = styled.div`
  margin-left: 12px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.gradients.button};
  border-radius: 50%;
`;

export const CreateUserButton: React.FC = () => {
  const handleCreateUser = () => createUserModel.createUser();

  return (
    <Button onClick={handleCreateUser}>
      <Title>Add User</Title>
      <BlueCircle>
        <PlusIcon />
      </BlueCircle>
    </Button>
  );
};
