import { Controller, useForm } from "react-hook-form";
import { useStore } from "effector-react";
import styled from "styled-components";

import { useServerFormErrors } from "shared/hooks";
import { Button } from "shared/ui/Button";
import { FormField } from "shared/ui/FormField";
import { Input } from "shared/ui/Input";
import { PasswordInput } from "shared/ui/PasswordInput";
import { Switcher } from "shared/ui/Switcher";
import { Typography } from "shared/ui/Typography";

import { $errors, createUserFx } from "../model";

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormTitle = styled(Typography)`
  margin-bottom: 42px;
`;

export type UserForm = {
  firstName: string;
  lastName: string;
  email: string;
  passwordFirst: string;
  passwordSecond: string;
  role: "ADMIN" | "STAFF";
};

const defaultValues: UserForm = {
  firstName: "",
  lastName: "",
  email: "",
  passwordFirst: "",
  passwordSecond: "",
  role: "ADMIN",
};

export const CreateUserForm = () => {
  const serverErrors = useStore($errors);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setError,
  } = useForm<UserForm>({ defaultValues });

  useServerFormErrors(serverErrors, setError);

  const passwordFirst = watch("passwordFirst");

  const onSubmit = (data: UserForm) => {
    const { passwordFirst, passwordSecond, ...rest } = data;

    createUserFx({ password: passwordFirst, ...rest });
  };

  const { ...firstNameProps } = register("firstName", {
    required: "This field is required",
  });

  const { ...lastNameProps } = register("lastName", {
    required: "This field is required",
  });

  const { ...emailProps } = register("email", {
    required: "This field is required",
  });

  const { ref: firstRef, ...firstProps } = register("passwordFirst", {
    required: "This field is required",
  });

  const { ref: secondRef, ...secondProps } = register("passwordSecond", {
    required: "This field is required",
    validate: {
      notMatch: (value) =>
        value !== passwordFirst ? "Passwords didn't match" : true,
    },
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormTitle variant="bold20">Add a new user</FormTitle>
      <Controller
        control={control}
        name="role"
        render={({ field: { onChange, value } }) => (
          <Switcher
            value={value}
            onChange={onChange}
            options={[
              { value: "ADMIN", label: "Admin" },
              { value: "STAFF", label: "Staff" },
            ]}
          />
        )}
      />

      <FormField error={errors.firstName?.message}>
        <Input
          placeholder="First Name"
          error={!!errors.firstName}
          {...firstNameProps}
        />
      </FormField>
      <FormField error={errors.lastName?.message}>
        <Input
          {...lastNameProps}
          placeholder="Last Name"
          error={!!errors.lastName}
        />
      </FormField>
      <FormField error={errors.email?.message}>
        <Input
          {...emailProps}
          placeholder="Email"
          error={!!errors.email}
        />
      </FormField>
      <FormField error={errors.passwordFirst?.message}>
        <PasswordInput
          placeholder="Password"
          inputRef={firstRef}
          error={!!errors.passwordFirst}
          {...firstProps}
        />
      </FormField>
      <FormField error={errors.passwordSecond?.message}>
        <PasswordInput
          placeholder="Re-enter password"
          inputRef={secondRef}
          error={!!errors.passwordSecond}
          {...secondProps}
        />
      </FormField>
      <Button type="submit">Continue</Button>
    </Form>
  );
};
