import { useStore } from "effector-react";

import { Modal } from "shared/ui/Modal";
import { Typography } from "shared/ui/Typography";

import {
  $failGiveCredits,
  $isGiveCreditsModalVisible,
  $successGiveCredits,
  hideGiveCreditsModal,
} from "../store/model";

import GiveCreditsForm from "./GiveCreditsForm";
interface Props {
  onClose: () => void;
}
export const GiveCreditsModal: React.FC<Props> = ({ onClose }) => {
  const isShow = useStore($isGiveCreditsModalVisible);
  const successGiveCredits = useStore($successGiveCredits);
  const failGiveCredits = useStore($failGiveCredits);
  return (
    <Modal
      isOpen={isShow}
      onClose={() => {
        hideGiveCreditsModal();
        onClose();
      }}
    >
      {successGiveCredits ? (
        <Typography variant="bold20">
          User balance has been successfully updated
        </Typography>
      ) : failGiveCredits ? (
        <Typography variant="bold20">Something went wrong</Typography>
      ) : (
        <GiveCreditsForm />
      )}
    </Modal>
  );
};
