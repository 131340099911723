import { forward } from "effector";
import { createGate } from "effector-react";

import { loadAccountFx } from "features/auth/model";

export const AppGate = createGate();

forward({
  from: AppGate.open,
  to: loadAccountFx,
});
