import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useStore } from "effector-react";
import styled from "styled-components";

import { Button } from "shared/ui/Button";
import { FormField } from "shared/ui/FormField";
import { Input } from "shared/ui/Input";
import { Typography } from "shared/ui/Typography";

import { $creditsRecipient, giveCustomerCreditsFX } from "../store/model";

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const FormTitle = styled(Typography)`
  margin-bottom: 42px;
`;

export type UserForm = {
  credits: number | string;
};

const defaultValues: UserForm = {
  credits: "",
};
const formRules = {
  required: {
    value: true,
    message: "Can't be empty",
  },
  pattern: {
    value: /^[0-9]+$/,
    message: "Please enter a positive number",
  },
  max: {
    value: 1000,
    message: "Please enter less then 1000",
  },
};

function GiveCreditsForm() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UserForm>({ defaultValues });
  const creditsRecipient = useStore($creditsRecipient);

  const onSubmit = (data: UserForm) => {
    const { credits } = data;
    credits &&
      creditsRecipient?.id &&
      giveCustomerCreditsFX({
        amount: credits,
        customerId: creditsRecipient?.id,
      });
  };
  const loading = useStore(giveCustomerCreditsFX.pending);
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormTitle variant="bold20">
          Give credits for{" "}
          {`${creditsRecipient?.firstName} ${creditsRecipient?.lastName}` ||
            "user"}
        </FormTitle>
        <Controller
          control={control}
          name="credits"
          rules={formRules}
          render={({ field: { onChange, value } }) => (
            <FormField error={errors.credits?.message}>
              <Input
                type="number"
                value={value}
                onChange={onChange}
                placeholder="Credits"
                error={!!errors.credits}
              />
            </FormField>
          )}
        />

        <Button type="submit">{loading ? "Loading..." : "Continue"}</Button>
      </Form>
    </>
  );
}

export default GiveCreditsForm;
