export const getFullName = (user: {
  firstName: string | null;
  lastName: string | null;
}) => {
  const firstName = user?.firstName?.trim();
  const lastName = user?.lastName?.trim();
  return firstName && lastName
    ? [firstName, lastName].filter((name) => name).join(" ")
    : "Customer";
};
