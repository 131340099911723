import { AxiosError } from "axios";
import { createEffect, createEvent, createStore } from "effector";

import { api, User } from "shared/api";

/** Only opens the modal */
export const createUser = createEvent();
export const cancelUserCreation = createEvent();

export const $user = createStore<User | null>(null);

export const $isCreateUserModalVisible = createStore(false);
export const $isSuccessMessageVisible = createStore(false);

export const $errors = createStore<ServerFormError[]>([]);

export const createUserFx = createEffect<
  User,
  any,
  AxiosError<FormErrorResponse>
>();

createUserFx.use(api.createUser);

$user
  .on(createUserFx.done, (_, { params }) => params)
  .reset(cancelUserCreation);

$isSuccessMessageVisible
  .on(createUserFx.done, () => true)
  .reset(cancelUserCreation);

$errors
  .on(createUserFx.fail, (_, { error }) => error.response?.data.errors || [])
  .reset([createUserFx.done, cancelUserCreation]);

$isCreateUserModalVisible.on(createUser, () => true).reset(cancelUserCreation);
