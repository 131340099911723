import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useStore } from "effector-react";
import styled from "styled-components";

import { FormField } from "shared/ui/FormField";

import { $loading, $passwordError, loginFx, resetLoginErrors } from "../features/auth/model";
import { Button } from "../shared/ui/Button";
import { Input } from "../shared/ui/Input";
import { PasswordInput } from "../shared/ui/PasswordInput";

const Container = styled.main`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VersionMark = styled.main`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 10px;
`;

const Form = styled.form`
  padding: 60px 42px 80px 42px;
  width: 428px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.gradients.background};
  border-radius: ${({ theme }) => theme.formBorderRadius};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadow};

  & > input:first-of-type {
    margin-bottom: 16px;
  }
`;

const FormButton = styled(Button)`
  margin-bottom: 24px;
`;

const FormLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text};

  font-weight: 500;
  font-size: 14px;
  line-height: 140%;

  /* identical to box height, or 20px */
  text-align: center;
  font-feature-settings: "pnum" on, "lnum" on, "ss09" on;
`;

const FormTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 21px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
`;

type LoginForm = {
  login: string;
  password: string;
};

const defaultValues: LoginForm = { login: "", password: "" };

export const LoginPage: React.FC = () => {
  const {
    register,
    handleSubmit,
  } = useForm<LoginForm>({ defaultValues });

  const { ref, ...passwordProps } = register("password", {
    required: "Password is required",
  });

  const passwordError = useStore($passwordError);
  
  const loading = useStore($loading);

  return (
    <Container>
      <Form onChange={()=>resetLoginErrors()
      } onSubmit={handleSubmit(loginFx)}>
        <FormTitle>Sign in</FormTitle>
        <FormField error={passwordError && "Incorrect login or password"}>
          <Input
            placeholder="Enter email"
            type="text"
            error={!!passwordError}
            {...register("login", { required: "Login is required" })}
          />
        </FormField>
        <FormField error={passwordError && "Incorrect login or password"}>
          <PasswordInput
            placeholder="Enter password"
            error={!!passwordError}
            inputRef={ref}
            {...passwordProps}
          />
        </FormField>
        <FormButton type="submit"> {loading ? "Loading..." : "SIGN IN"}</FormButton>
        <FormLink to="/forgot">Forgot Password?</FormLink>
      </Form>
      <VersionMark>0.1.1</VersionMark>
    </Container>
  );
};
