import { transparentize } from "polished";
import { DefaultTheme } from "styled-components";

const tableRowGradientStartColor = transparentize(0.8, "#6E62A7");

const customTheme: DefaultTheme = {
  formBorderRadius: "30px",
  controlBorderRadius: "15px",
  colors: {
    background: "#251F37",
    darkBackground: "#030a23",
    text: "#fff",
    input: "#b2b5ff",
    active: "#05fe86",
    inactive: "rgba(255, 255, 255, 0.4)",
    error: "#d0224b",
  },
  gradients: {
    background:
      "linear-gradient(167.96deg, #1F2340 -56.33%, #2E253D 46.84%, #010922 153.67%);",
    button: "linear-gradient(93.96deg, #2F05D7 -15.41%, #69F6FF 178.05%)",
    yellow: "linear-gradient(135deg, #fdcc06 0%, #f8d134 50%, #feb907 100%)",
    tableRow: `linear-gradient(91.4deg, ${tableRowGradientStartColor} 0%, transparent 81.26%)`,
    activeRace:
      "linear-gradient(90.03deg, rgba(5, 254, 134, 0.45) 0.03%, rgba(0, 166, 86, 0) 149%)",
  },
  shadow: "0px 4px 10px rgba(3, 10, 35, 0.25);",
  typography: {
    fontFamily: "'Inter', sans-serif;",
    regular16: {
      fontFamily: "'Inter', sans-serif;",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "21px",
      letterSpacing: "0em",
      fontFeatureSettings: "'pnum' on, 'lnum' on, 'ss09' on;",
    },
    semiBold16: {
      fontFamily: "'Inter', sans-serif;",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "0em",
      fontFeatureSettings: "'pnum' on, 'lnum' on, 'ss09' on;",
    },
    semiBold18: {
      fontFamily: "'Inter', sans-serif;",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "140%",
      fontFeatureSettings: "'pnum' on, 'lnum' on, 'ss09' on;",
    },
    bold20: {
      fontFamily: "'Inter', sans-serif;",
      fontSize: "20px;",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "140%;",
    },
  },
};

export { customTheme };
