import {
  createEffect,
  createEvent,
  createStore,
  forward,
  restore,
} from "effector";

import { api } from "shared/api";
import { CustomerDetails, CustomerPayment } from "shared/types/customer";

const loadCustomerDetailsFx = createEffect<string, CustomerDetails>(
  api.fetchCustomerDetails
);
const loadCustomerPaymentsFx = createEffect<string, CustomerPayment[]>(
  api.fetchCustomerPayments
);

export const showCustomerInfo = createEvent<string>();
export const hideCustomerInfo = createEvent();

export const $customerDetails = restore(loadCustomerDetailsFx, null);
export const $customerPayments = restore(loadCustomerPaymentsFx, []);

export const $customerPaymentsLoaded = createStore(false).on(
  loadCustomerPaymentsFx.done,
  () => true
);
export const $customerId = restore(showCustomerInfo, null).reset(
  hideCustomerInfo
);

export const $isCustomerInfoVisible = $customerId.map((state) => !!state);

forward({
  from: showCustomerInfo,
  to: [loadCustomerDetailsFx, loadCustomerPaymentsFx],
});
