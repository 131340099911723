import { createEffect, createStore, forward } from "effector";
import { createGate } from "effector-react";

import { api } from "shared/api";
import { createPageable } from "shared/paging/model";

import {
  assignUserModel,
  createUserModel,
  deleteUserModel,
  removeUserFromAdminsModel,
} from "features/user";

export const loadTotalsFx = createEffect(api.fetchCustomersAndManagersTotals);

export const {
  $data,
  $isEmpty,
  loadFirst,
  loadNext,
  loadPrev,
  update,
  changePageSize,
} = createPageable(api.fetchUsers);

export const $usersTotal = createStore<number>(0);

export const UsersGate = createGate();

$usersTotal.on(loadTotalsFx.doneData, (_, result) => result.managersTotal);

forward({
  from: UsersGate.open,
  to: loadFirst,
});

forward({
  from: [
    createUserModel.createUserFx.done,
    deleteUserModel.deleteUserFx.done,
    assignUserModel.assignUserAsAdminFx.done,
    removeUserFromAdminsModel.removeUserFromAdminsFx.done,
  ],
  to: update,
});

forward({
  from: [
    UsersGate.open,
    createUserModel.createUserFx.done,
    deleteUserModel.deleteUserFx.done,
  ],
  to: loadTotalsFx,
});
