import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

type Props = {
  value: number;
  label: string;
  color: "orange" | "yellow";
  size: "small" | "large";
};

const colors = {
  orange: ["#f96e09", "#ffa869"],
  yellow: ["#feb907", "#fdcc06"],
};

export const RadialChart: React.FC<Props> = ({ value, label, color, size }) => {
  const config: ApexOptions = {
    grid: {
      padding: {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
      },
    },
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 360,
        hollow: {
          size: size === "small" ? "55%" : "60%",
        },
        track: {
          background: "rgba(255, 255, 255, 0.4)",
          margin: 0,
        },
        dataLabels: {
          name: {
            color: "#ffffff",
            fontWeight: 600,
            fontSize: "16px",
            offsetY: 30,
          },
          value: {
            fontFamily: "'Inter', sans-serif;",
            fontSize: "36px",
            fontWeight: 600,
            color: "#ffffff",
            formatter: (val: number) => `${val}%`,
            offsetY: -15,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      colors: [colors[color][0]],
      gradient: {
        shade: "dark",
        type: "diagonal2",
        shadeIntensity: 0.5,
        gradientToColors: [colors[color][1]],
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: [label],
  };

  return (
    <ReactApexChart
      options={config}
      series={[value]}
      type="radialBar"
      width={size === "small" ? "280" : "380"}
    />
  );
};
